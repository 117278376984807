import { IconMail, IconPersonMemo, IconTemplateStep } from '@onsaui/icons'
import cx from 'clsx'

import useIcpStatistics from '@/modules/icp/hooks/useIcpStatistics'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import StatSlot from './StatSlot'

const IcpStatisticsWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const statisticsQuery = useIcpStatistics(icpId)

  return (
    <RenderQuery
      query={statisticsQuery}
      renderPending={null}
      renderError={(error) => {
        return (
          <div className={cx('flex flex-row gap-5 rounded-[20px] bg-level1 p-5', className)}>
            <p className="text-danger">{getErrorMessage(error)}</p>
          </div>
        )
      }}
    >
      {(statistics) => {
        if (statistics.outreached === 0) {
          return null
        }

        return (
          <div className={cx('flex flex-row gap-5 rounded-[20px] bg-level1 p-5', className)}>
            <StatSlot
              className="flex-1"
              Icon={IconMail}
              value={statistics.outreached}
              description="Prospects Contacted"
            />
            <StatSlot
              className="flex-1"
              Icon={IconPersonMemo}
              value={statistics.connected}
              description="Connections Accepted"
            />
            <StatSlot
              className="flex-1"
              Icon={IconTemplateStep}
              value={statistics.replied}
              description="Prospects Responded"
            />
            <StatSlot
              className="flex-1"
              value={`${statistics.successRate}%`}
              description="Success Rate"
            />
          </div>
        )
      }}
    </RenderQuery>
  )
}

export default IcpStatisticsWidget
