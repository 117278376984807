import { Button, Input, notify, TextArea } from '@onsaui'
import cx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import useOutreachTemplateActions from '@/modules/icp/hooks/useOutreachTemplateActions'
import { OutreachTemplate } from '@/modules/icp/model'

const OutreachTemplateItemEdit: React.FC<{
  icpId: string
  outreachTemplate: OutreachTemplate
  onClose: () => void
  className?: string
}> = ({ icpId, outreachTemplate, onClose, className }) => {
  const { updateOutreachTemplate, isUpdating } = useOutreachTemplateActions()

  const [title, setTitle] = useState(outreachTemplate.title)
  const [message, setMessage] = useState(outreachTemplate.message)

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleSave = async () => {
    const canBeSaved = title.trim().length > 0 && message.trim().length > 0
    if (!canBeSaved) {
      notify({ message: 'Title and Message should not be empty', variant: 'error' })
      return
    }

    await updateOutreachTemplate(icpId, outreachTemplate.id, {
      title: title.trim(),
      message: message.trim(),
      liked: true, // reset liked status
    })

    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <div className={cx('flex flex-col rounded-3xl bg-level1 p-5', className)}>
      <Input
        className="w-full"
        inputClassName="!rounded-2xl"
        placeholder="Add template title"
        onChange={handleTitleChange}
        maxLength={200}
        value={title}
        isDisabled={isUpdating}
      />

      <TextArea
        className="mt-2 w-full"
        inputClassName="!rounded-2xl"
        placeholder="Type your message here..."
        autoHeight={{ enabled: true, minLines: 3, maxLines: 10 }}
        onChange={handleMessageChange}
        value={message}
        isDisabled={isUpdating}
        autoFocus
      />

      <div className="mt-4 flex flex-row justify-end gap-2">
        <Button
          type="button"
          size="medium"
          variant="outlined"
          onClick={handleClose}
          isDisabled={isUpdating}
        >
          Cancel
        </Button>
        <Button
          type="button"
          size="medium"
          variant="primary"
          onClick={handleSave}
          isDisabled={isUpdating}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default OutreachTemplateItemEdit
