import { OutreachStageStatus, OutreachStageType } from '@/modules/icp/model'
import { ApiService } from '@/services/api'

import { icpLeadAdapter, icpLeadsWithStatusAdapter } from './icpLeadsApiAdapters'
import { IcpLeadResponse, IcpLeadsWithStatusResponse } from './icpLeadsApiInterfaces'
import { outreachDetailsAdapter, outreachTemplateAdapter } from './outreachApiAdapters'
import { OutreachDetailsResponse, OutreachTemplateResponse } from './outreachApiInterfaces'

export const getOutreachTemplates = async (icpId: string) => {
  const response = await ApiService.workspaceInstance.get<OutreachTemplateResponse[]>(
    `/icps/${icpId}/outreach-templates`,
  )

  return response.data.map((template) => outreachTemplateAdapter(template))
}

export const createOutreachTemplate = async (
  icpId: string,
  title: string,
  message: string,
  position: number = 100,
) => {
  const response = await ApiService.workspaceInstance.post<OutreachTemplateResponse>(
    `/icps/${icpId}/outreach-templates/create`,
    { title, message, position },
  )

  return outreachTemplateAdapter(response.data)
}

export const updateOutreachTemplate = async (
  icpId: string,
  templateId: string,
  changes: Partial<OutreachTemplateResponse>,
) => {
  const response = await ApiService.workspaceInstance.patch<OutreachTemplateResponse>(
    `/icps/${icpId}/outreach-templates/${templateId}`,
    changes,
  )

  return outreachTemplateAdapter(response.data)
}

export const deleteOutreachTemplate = async (icpId: string, templateId: string) => {
  await ApiService.workspaceInstance.delete(`/icps/${icpId}/outreach-templates/${templateId}`)
}

const OutreachDetailsMock: OutreachDetailsResponse = {
  fullName: 'John Doe',
  linkedInUrl: 'https://linkedin.com/johndoe',
  actions: [
    {
      type: OutreachStageType.Connect,
      status: OutreachStageStatus.Positive,
      start: '2021-08-01T09:00:00',
      finish: '2021-08-01T09:00:00',
      message: null,
      replyMessage: null,
      waitUntil: null,
    },
    {
      type: OutreachStageType.Outreach,
      status: OutreachStageStatus.Pending,
      start: '2021-08-01T09:00:00',
      finish: null,
      message: "Let's schedule a call bro",
      replyMessage: null,
      waitUntil: null,
    },
    {
      type: OutreachStageType.FollowUp1,
      status: null,
      start: null,
      finish: null,
      message: 'Received a positive reply',
      replyMessage: null,
      waitUntil: null,
    },
  ],
  isRunning: true,
  isTerminalState: false,
}

export const getOutreachDetails = async (icpId: string, leadId: string) => {
  const response = await ApiService.workspaceInstance.get<OutreachDetailsResponse>(
    `/icps/${icpId}/leads/${leadId}/actions`,
  )

  // const response = await new Promise<OutreachDetails>((resolve) => {
  //   setTimeout(() => {
  //     resolve(outreachDetailsAdapter(OutreachDetailsMock))
  //   }, 1000)
  // })

  return outreachDetailsAdapter(response.data)
}

export const startOutreachAll = async (icpId: string) => {
  const response = await ApiService.workspaceInstance.post<IcpLeadsWithStatusResponse>(
    `/icps/${icpId}/leads/all/outreach`,
  )

  return icpLeadsWithStatusAdapter(response.data)
}
export const startOutreachLead = async (icpId: string, leadId: string) => {
  const response = await ApiService.workspaceInstance.post<IcpLeadResponse>(
    `/icps/${icpId}/leads/${leadId}/outreach`,
  )

  return icpLeadAdapter(response.data)
}
export const stopOutreachLead = async (icpId: string, leadId: string) => {
  const response = await ApiService.workspaceInstance.post<IcpLeadResponse>(
    `/icps/${icpId}/leads/${leadId}/stop-outreach`,
  )

  return icpLeadAdapter(response.data)
}

export const updateOutreachStageMessage = async (
  icpId: string,
  leadId: string,
  stageType: OutreachStageType,
  message: string,
) => {
  const response = await ApiService.workspaceInstance.put<OutreachDetailsResponse>(
    `/icps/${icpId}/leads/${leadId}/message`,
    { type: stageType, text: message },
  )

  return outreachDetailsAdapter(response.data)
}
