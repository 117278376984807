import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpReferenceLeadsWidget from '@/modules/icp/components/IcpReferenceLeadsWidget/IcpReferenceLeadsWidget'
import IcpStageWidget from '@/modules/icp/components/IcpStageWidget/IcpStageWidget'
import { IcpStage } from '@/modules/icp/model'

const IcpLeadsPage: React.FC = () => {
  const { icpId } = useParams()

  return (
    <DefaultPageLayout>
      <IcpStageWidget icpId={icpId!} currentStage={IcpStage.ReferenceLeads} className="mb-5" />
      <EnsureProcessedIcp icpId={icpId!}>
        {(icp) => {
          return <IcpReferenceLeadsWidget className="flex-grow" icpId={icpId!} />
        }}
      </EnsureProcessedIcp>
    </DefaultPageLayout>
  )
}

export default IcpLeadsPage
