import { IconIcp, IconPersonMemo, IconRecording } from '@onsaui/icons'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { icpActions } from '@/modules/icp'
import SidebarIcpList from '@/modules/icp/components/SidebarIcpList/SidebarIcpList'
import useIcpEnabled from '@/modules/icp/hooks/useIcpEnabled'
import { taskActions } from '@/modules/task'
import SidebarTaskList from '@/modules/task/components/SidebarTaskList/SidebarTaskList'
import { TaskType } from '@/modules/task/model'

import TasksCategory from './TasksCategory'

type Category = 'icp' | 'memo' | 'transcript'

const WorkspaceSidebar: React.FC = () => {
  const { icpId, taskId } = useParams()

  useEffect(() => {
    taskActions.prefetchTasks()
    icpActions.prefetchIcps()
  }, [])

  const { isIcpEnabled } = useIcpEnabled()

  const [openCategory, setOpenCategory] = useState<Category | null>(null)
  const handleToggleCategory = (category: Category) => {
    setOpenCategory((prev) => (prev === category ? null : category))
  }

  return (
    <div className="flex flex-col overflow-auto px-6 pb-4">
      {isIcpEnabled ? (
        <TasksCategory
          className="overflow-auto"
          Icon={IconIcp}
          title="ICP"
          isOpen={openCategory === 'icp'}
          onToggle={() => handleToggleCategory('icp')}
        >
          <SidebarIcpList className="min-h-0 overflow-auto" selectedIcpId={icpId} />
        </TasksCategory>
      ) : null}

      <TasksCategory
        className="overflow-auto"
        Icon={IconPersonMemo}
        title="Person Memos"
        isOpen={openCategory === 'memo'}
        onToggle={() => handleToggleCategory('memo')}
      >
        <SidebarTaskList
          className="min-h-0 overflow-auto"
          selectedTaskId={taskId}
          taskTypeFilter={TaskType.PersonMemo}
        />
      </TasksCategory>

      <TasksCategory
        className="overflow-auto"
        Icon={IconRecording}
        title="Eval Reports"
        isOpen={openCategory === 'transcript'}
        onToggle={() => handleToggleCategory('transcript')}
      >
        <SidebarTaskList
          className="min-h-0 overflow-auto"
          selectedTaskId={taskId}
          taskTypeFilter={TaskType.TranscriptReport}
        />
      </TasksCategory>
    </div>
  )
}
export default WorkspaceSidebar
