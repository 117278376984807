import { OutreachStageStatus, OutreachStageType } from './outreachInterfaces'

export const OutreachStatusLabelMap: Record<
  OutreachStageType,
  Record<OutreachStageStatus, string>
> = {
  [OutreachStageType.Connect]: {
    [OutreachStageStatus.Scheduled]: 'Connection scheduled',
    [OutreachStageStatus.Pending]: 'Waiting for response',
    [OutreachStageStatus.Positive]: 'Connected',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
  },

  [OutreachStageType.Outreach]: {
    [OutreachStageStatus.Scheduled]: 'Initial message scheduled',
    [OutreachStageStatus.Pending]: 'Waiting for response',
    [OutreachStageStatus.Positive]: 'Replied',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
  },

  [OutreachStageType.FollowUp1]: {
    [OutreachStageStatus.Scheduled]: 'Follow up #1 scheduled',
    [OutreachStageStatus.Pending]: 'Waiting for response',
    [OutreachStageStatus.Positive]: 'Replied',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
  },
  [OutreachStageType.FollowUp2]: {
    [OutreachStageStatus.Scheduled]: 'Follow up #2 scheduled',
    [OutreachStageStatus.Pending]: 'Waiting for response',
    [OutreachStageStatus.Positive]: 'Replied',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
  },
}

export const OutreachStageNameMap: Record<OutreachStageType, string> = {
  [OutreachStageType.Connect]: 'Connection',
  [OutreachStageType.Outreach]: 'Initial message',
  [OutreachStageType.FollowUp1]: 'Follow up #1',
  [OutreachStageType.FollowUp2]: 'Follow up #2',
}

export const getOutreachStatusLabel = (stage: OutreachStageType, status: OutreachStageStatus) => {
  return OutreachStatusLabelMap[stage][status]
  // return 'asd'
}

export const getOutreachStageName = (stage: OutreachStageType) => {
  return OutreachStageNameMap[stage]
}
