import { Dayjs } from 'dayjs'

export interface OutreachTemplate {
  id: string
  title: string
  message: string
  position: number
  liked: boolean
}

export enum OutreachStageType {
  Connect = 'connect',
  Outreach = 'outreach',
  FollowUp1 = 'follow-up-1',
  FollowUp2 = 'follow-up-2',
}

export enum OutreachStageStatus {
  Scheduled = 'scheduled',
  Pending = 'pending',
  Positive = 'positive',
  Negative = 'negative',
  StoppedByUser = 'stopped-by-user',
}

export interface OutreachStage {
  type: OutreachStageType
  status?: OutreachStageStatus
  start?: Dayjs
  finish?: Dayjs
  message?: string
  replyMessage?: string
  waitUntil?: Dayjs
}

export interface OutreachDetails {
  fullName: string
  linkedInUrl: string
  stages: OutreachStage[]
  isRunning: boolean
  isTerminalState: boolean
}
