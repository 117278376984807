import { IcpStage } from './icpInterfaces'

export const getIcpStageRoute = (stage: IcpStage) => {
  const stageRouteMap = {
    [IcpStage.SetContext]: 'context',
    [IcpStage.ReferenceLeads]: 'leads',
    [IcpStage.MessageTemplates]: 'templates',
    [IcpStage.Confirmation]: 'confirmation',
  }

  // @ts-expect-error untyped map
  const route = stageRouteMap[stage]
  if (!route) {
    throw new Error(`Route is undefined for stage: ${stage}`)
  }

  return route
}

export const isStageCompleted = (currentStage: IcpStage, maxStage: IcpStage) => {
  const StageOrder = [
    IcpStage.SetContext,
    IcpStage.ReferenceLeads,
    IcpStage.MessageTemplates,
    IcpStage.Confirmation,
    IcpStage.Finalized,
  ]

  return StageOrder.indexOf(maxStage) > StageOrder.indexOf(currentStage)
}
