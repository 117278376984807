import { icpApi } from '@/modules/icp/api'
import { Icp } from '@/modules/icp/model'
import { QueryService } from '@/services/query'

export const invalidateIcp = (id: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['icp', id] })
}

export const invalidateIcps = () => {
  QueryService.getClient().invalidateQueries({ queryKey: ['icps'] })
}

export const setIcp = (icp: Icp) => {
  QueryService.getClient().setQueryData(['icp', icp.id], icp)
}

export const updateIcp = async (id: string, changes: Partial<Icp>) => {
  const icp = await icpApi.updateIcp(id, changes)
  setIcp(icp)

  return icp
}

export const regenerateIcp = async (id: string) => {
  const icp = await icpApi.regenerateIcp(id)
  setIcp(icp)

  return icp
}

export const deleteIcp = async (id: string) => {
  await icpApi.deleteIcp(id)

  QueryService.getClient().removeQueries({ queryKey: ['icp', id] })
  QueryService.getClient().setQueriesData({ queryKey: ['icps'] }, (oldData?: Icp[]) => {
    if (!oldData) {
      return oldData
    }

    return oldData.filter((icp) => icp.id !== id)
  })
}

export const prefetchIcps = () => {
  QueryService.getClient().prefetchQuery({ queryKey: ['icps'], queryFn: icpApi.getIcps })
}

export const addFile = async (
  icpId: string,
  fileConfig: { fileName: string; fileSize: number; mimeType: string },
) => {
  const icp = await icpApi.addFile(icpId, fileConfig)
  setIcp(icp)

  return icp
}

export const deleteFile = async (icpId: string, fileId: string) => {
  const icp = await icpApi.deleteFile(icpId, fileId)
  setIcp(icp)

  return icp
}
