import { Button } from '@onsaui'
import { IconLinkedIn } from '@onsaui/icons'

import useUserMe from '@/modules/user/hooks/useUserMe'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const ConnectionStatus: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const userQuery = useUserMe()

  const avatarUrl = userQuery.data?.linkedInConnection?.avatarUrl
  const avatar = avatarUrl ? (
    <img
      src={avatarUrl}
      alt={userQuery.data?.firstName}
      className="mb-4 h-24 w-24 shrink-0 rounded-full"
    />
  ) : (
    <IconLinkedIn className="mb-4 h-24 w-24 shrink-0" />
  )

  return (
    <>
      {avatar}
      <RenderQuery query={userQuery} className="mb-4 mt-6">
        {({ linkedInConnection }) => {
          const fullName = `${linkedInConnection?.firstName} ${linkedInConnection?.lastName}`

          if (!linkedInConnection || !linkedInConnection.isActive) {
            return (
              <h3 className="font-semibold text-danger">
                It seems your LinkedIn connection is inactive or disconnected. Please try again.
              </h3>
            )
          }

          return (
            <>
              <h2 className="mb-6 font-semibold">Account successfully connected</h2>

              <a
                className="text-primary"
                href={linkedInConnection.linkedInUrl}
                target="_blank"
                rel="noreferrer"
              >
                {linkedInConnection.linkedInUrl}
              </a>
            </>
          )
        }}
      </RenderQuery>
      <Button className="mt-10 w-full" onClick={onClose} isDisabled={userQuery.isPending}>
        Continue
      </Button>
    </>
  )
}

export default ConnectionStatus
