import { notify } from '@onsaui'
import { IconTrash } from '@onsaui/icons'
import cx from 'clsx'

import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import { Icp } from '@/modules/icp/model'

import IcpFileDropArea from './IcpFileDropArea'

const IcpFiles: React.FC<{ icp: Icp; className?: string }> = ({ icp, className }) => {
  const { deleteFile, isFileDeleting, addFile, isFileAdding } = useIcpActions()

  const handleFileSelected = async (file: File) => {
    await addFile(icp.id, file)

    notify({ message: 'File uploaded', variant: 'success' })
  }

  const handleDeleteFile = async (fileName: string) => {
    deleteFile(icp.id, fileName)
  }

  return (
    <div className={cx('', className)}>
      {icp.files.map((file) => (
        <div
          key={file.originName}
          className="group flex flex-row items-center justify-between overflow-hidden rounded-xl px-2 py-4"
        >
          <p className="truncate">{file.originName}</p>

          <button
            type="button"
            className="ml-2 p-1 text-secondary opacity-0 transition-all hover:text-danger group-hover:opacity-100"
            onClick={() => handleDeleteFile(file.originName)}
            aria-label="Delete file"
            disabled={isFileDeleting}
          >
            <IconTrash />
          </button>
        </div>
      ))}

      <IcpFileDropArea
        onFileSelected={handleFileSelected}
        className="mt-2 w-full"
        isLoading={isFileAdding}
      />
    </div>
  )
}

export default IcpFiles
