import { IllustrationIcp } from '@onsaui/icons'

import CreateTaskCard from '@/modules/general/components/CreateTaskCard/CreateTaskCard'
import { Icp } from '@/modules/icp/model'

import CreateIcpForm from '../CreateIcpForm/CreateIcpForm'

const CreateIcpCard: React.FC<{
  onIcpCreated?: (icp: Icp) => void
  className?: string
  isAnon?: boolean
  creditsCost?: number
}> = ({ onIcpCreated, className, isAnon, creditsCost }) => {
  return (
    <CreateTaskCard
      className={className}
      price={creditsCost}
      Illustration={IllustrationIcp}
      title="Find Prospect Leads"
      description="Send me web site link to the company  you would like to find leads for."
      buttonLabel="Search for Leads"
      modalContent={<CreateIcpForm onIcpCreated={onIcpCreated} isAnon={isAnon} />}
    />
  )
}

export default CreateIcpCard
