import { Button } from '@onsaui'
import cx from 'clsx'

import useOutreachActions from '@/modules/icp/hooks/useOutreachActions'
import useOutreachDetails from '@/modules/icp/hooks/useOutreachDetails'
import { OutreachStageStatus, OutreachStageType } from '@/modules/icp/model'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import OutreachStageItem from './OutreachStageItem'

const OutreachDetailsWidget: React.FC<{ icpId: string; leadId: string; className?: string }> = ({
  icpId,
  leadId,
  className,
}) => {
  const detailsQuery = useOutreachDetails(icpId, leadId)
  const details = detailsQuery.data

  const { startOutreachLead, stopOutreachLead, isProcessing } = useOutreachActions()

  const handleStartOutreach = () => {
    startOutreachLead(icpId, leadId)
  }
  const handleStopOutreach = () => {
    stopOutreachLead(icpId, leadId)
  }

  return (
    <div className={cx('flex min-h-[300px] flex-col rounded-3xl bg-level1 p-5', className)}>
      <div className="mb-5 flex min-h-10 flex-row items-center">
        <h3 className="font-semibold">Outreach History</h3>
        {details ? (
          <a
            className="ml-2 text-h3 text-primary"
            href={details.linkedInUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            {details.fullName}
          </a>
        ) : null}

        {details && !details.isRunning && !details.isTerminalState ? (
          <Button
            variant="outlined"
            size="medium"
            className="ml-auto"
            onClick={handleStartOutreach}
            isLoading={isProcessing}
            isDisabled={isProcessing}
          >
            Start Outreach
          </Button>
        ) : null}

        {details && details.isRunning && !details.isTerminalState ? (
          <Button
            variant="outlined"
            size="medium"
            className="ml-auto"
            onClick={handleStopOutreach}
            isLoading={isProcessing}
            isDisabled={isProcessing}
          >
            Stop Outreach
          </Button>
        ) : null}
      </div>

      <RenderQuery query={detailsQuery}>
        {(outreachDetails) => {
          return (
            <div className="flex flex-col gap-8">
              {outreachDetails.stages.map((stage, n) => {
                if (outreachDetails.isTerminalState && !stage.status) {
                  return null
                }

                const showFollowUpLabel =
                  (stage.type === OutreachStageType.Outreach ||
                    stage.type === OutreachStageType.FollowUp1) &&
                  (stage.status === OutreachStageStatus.Scheduled ||
                    stage.status === OutreachStageStatus.Pending) &&
                  stage.waitUntil

                return (
                  <>
                    <OutreachStageItem
                      key={stage.type}
                      icpId={icpId}
                      leadId={leadId}
                      stage={stage}
                    />
                    {showFollowUpLabel ? (
                      <div className="self-center rounded-[10px] bg-disabled-surface px-3 py-2 text-secondary">
                        Follow up {stage.waitUntil?.fromNow()}
                      </div>
                    ) : null}
                    {stage.replyMessage ? (
                      <div className="bg-success-surface max-w-sm self-end rounded-[10px] p-5">
                        <p>{stage.replyMessage}</p>
                      </div>
                    ) : null}
                  </>
                )
              })}
            </div>
          )
        }}
      </RenderQuery>
    </div>
  )
}

export default OutreachDetailsWidget
