import { useIsDesktopScreen } from '@onsaui'
import cx from 'clsx'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import { IconSidebar } from '@/onsaui/icons'

import cm from './GeneralLayout.module.css'

const GeneralLayoutContext = React.createContext<{ toggleSidebar: () => void }>({
  toggleSidebar: () => {},
})
export const useGeneralLayout = () => React.useContext(GeneralLayoutContext)

const GeneralLayout: React.FC<{
  sidebar?: React.ReactNode
  sidebarHeader?: React.ReactNode
  header?: React.ReactNode
  body?: React.ReactNode
  noSidebar?: boolean
}> = ({ sidebar, sidebarHeader, header, body, noSidebar }) => {
  const isDesktop = useIsDesktopScreen()

  const [isSidebarOpen, setIsSidebarOpen] = useState(isDesktop)
  const [isFirstOpen, setIsFirstOpen] = useState(isDesktop)

  const toggleSidebar = useCallback(() => {
    setIsFirstOpen(false)
    setIsSidebarOpen(!isSidebarOpen)
  }, [isSidebarOpen])

  const sidebarRef = useRef<HTMLDivElement>(null)
  const handleClickOnElement = (el: HTMLElement) => {
    const sidebarEl = sidebarRef.current
    if (!sidebarEl) {
      return
    }

    const interactiveElements = sidebarEl.getElementsByClassName('sidebar-int')
    const isInteractiveElement = Array.from(interactiveElements).some((element) =>
      element.contains(el),
    )

    if (isInteractiveElement) {
      setTimeout(() => toggleSidebar(), 10)
    }
  }
  const handleSidebarClick = (e: React.MouseEvent) => {
    if (!isDesktop) {
      handleClickOnElement(e.target as HTMLElement)
    }
  }

  const contextProps = useMemo(() => ({ toggleSidebar }), [toggleSidebar])

  return (
    <GeneralLayoutContext.Provider value={contextProps}>
      <div className="relative flex max-h-full min-h-full w-full flex-row">
        {isSidebarOpen && !noSidebar && (
          <>
            {!isDesktop && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div className="fixed inset-0 bg-black/5" onClick={toggleSidebar} />
            )}

            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */}
            <aside
              className={cx(
                'flex w-[320px] min-w-[320px] shrink-0 flex-col overflow-auto bg-level1',
                {
                  'absolute left-0 top-0 z-20 h-full shadow-2xl': !isDesktop,
                },
                !isFirstOpen && cm.slideFromLeft,
              )}
              ref={sidebarRef}
              onClick={handleSidebarClick}
            >
              <div className="sticky top-0 z-10 flex min-h-[112px] flex-row items-center px-6 backdrop-blur-sm">
                <button type="button" onClick={toggleSidebar} aria-label="Close sidebar">
                  <IconSidebar />
                </button>
                {sidebarHeader}
              </div>
              {sidebar}
            </aside>
          </>
        )}
        <main className="flex min-h-full flex-grow flex-col overflow-auto bg-[#EFF2F6] mobile:w-full">
          <div className="sticky top-0 z-10 flex min-h-[112px] shrink-0 flex-row items-center px-6 backdrop-blur-sm">
            {!isSidebarOpen && !noSidebar && (
              <button
                type="button"
                className="mr-4"
                onClick={toggleSidebar}
                aria-label="Open sidebar"
              >
                <IconSidebar />
              </button>
            )}
            {header}
          </div>

          {body}
        </main>
      </div>
    </GeneralLayoutContext.Provider>
  )
}

export default GeneralLayout
