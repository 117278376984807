import { Button } from '@onsaui'
import { IconChevronDownSm } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'

import useIcpReferenceLeads from '@/modules/icp/hooks/useIcpReferenceLeads'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import LeadPreviewItem from './LeadPreviewItem'

const IcpLeadsPreviewWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const leadsQuery = useIcpReferenceLeads(icpId)

  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <div className={cx('flex flex-col rounded-3xl bg-level1 p-5', className)}>
      <h3 className="mb-5 font-semibold">Prospect References</h3>

      <RenderQuery query={leadsQuery}>
        {(leadsWithStatus) => {
          if (!leadsWithStatus.leads.length) {
            return (
              <div className="flex flex-grow flex-col items-center justify-center">
                <p>No reference leads have been generated yet 🙈</p>
              </div>
            )
          }

          const MAX_LEADS = 5
          const leadsToShow = isExpanded
            ? leadsWithStatus.leads
            : leadsWithStatus.leads.slice(0, MAX_LEADS)

          return (
            <>
              <div className="flex flex-col gap-5">
                {leadsToShow.map((icpLead) => (
                  <LeadPreviewItem key={icpLead.id} icpLead={icpLead} />
                ))}
              </div>
              {leadsWithStatus.leads.length > MAX_LEADS ? (
                <Button variant="ghost" className="self-start" onClick={toggleExpanded}>
                  {isExpanded ? <span>View Less</span> : <span>View All</span>}
                  <IconChevronDownSm className={cx('ml-1', { 'rotate-180': isExpanded })} />
                </Button>
              ) : null}
            </>
          )
        }}
      </RenderQuery>
    </div>
  )
}

export default IcpLeadsPreviewWidget
