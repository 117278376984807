import { Button } from '@onsaui'
import { IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { Link, useParams } from 'react-router-dom'

import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import useIcps from '@/modules/icp/hooks/useIcps'
import { IcpMinimal } from '@/modules/icp/model'
import { InternalTaskType } from '@/modules/task/model'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const SidebarIcpList: React.FC<{
  selectedIcpId?: string

  className: string
}> = ({ selectedIcpId, className }) => {
  const { workspaceId } = useParams()
  const icpsQuery = useIcps()

  const { deleteIcp } = useIcpActions()
  const handleDeleteIcp = (icp: IcpMinimal) => {
    deleteIcp(icp.id)
  }

  return (
    <RenderQuery query={icpsQuery} className={cx('min-h-[200px]', className)}>
      {(icps) => {
        if (!icps.length) {
          return (
            <div
              className={cx('flex min-h-[120px] flex-col items-center justify-center', className)}
            >
              <p className="text-secondary">Your ICPs will appear here</p>
              <Button className="mt-2" size="small">
                <Link to={`/${workspaceId}?highlight=${InternalTaskType.CreateIcp}`}>
                  Create ICP
                </Link>
              </Button>
            </div>
          )
        }

        return (
          <ul className={cx('flex flex-col gap-2', className)}>
            {icps.map((icp) => {
              return (
                <li
                  key={icp.id}
                  className="group relative rounded-2xl p-2 pr-8 hover:bg-disabled-surface"
                >
                  <Link
                    to={`/${workspaceId}/icp/${icp.id}`}
                    className={cx(
                      'sidebar-int flex min-h-8 w-full flex-row items-center gap-2 text-left',
                      {
                        'text-primary': icp.id === selectedIcpId,
                      },
                    )}
                  >
                    <div className="flex flex-col justify-center overflow-hidden">
                      <p className="line-clamp-2">{icp.title}</p>
                    </div>
                  </Link>
                  <button
                    type="button"
                    onClick={() => handleDeleteIcp(icp)}
                    className={cx(
                      'absolute right-0 top-0 flex h-full flex-col items-center justify-center px-2 text-secondary opacity-0 transition-all hover:text-danger group-hover:flex group-hover:opacity-100',
                    )}
                    aria-label="Delete ICP"
                  >
                    <IconTrash />
                  </button>
                </li>
              )
            })}
          </ul>
        )
      }}
    </RenderQuery>
  )
}

export default SidebarIcpList
