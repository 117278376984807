import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpBody from '@/modules/icp/components/IcpContextWidget/IcpBody'
import IcpContext from '@/modules/icp/components/IcpContextWidget/IcpContext'
import IcpStageWidget from '@/modules/icp/components/IcpStageWidget/IcpStageWidget'
import { IcpStage } from '@/modules/icp/model'

const IcpContextPage: React.FC = () => {
  const { icpId } = useParams()
  return (
    <DefaultPageLayout>
      <IcpStageWidget icpId={icpId!} currentStage={IcpStage.SetContext} className="mb-5" />

      <EnsureProcessedIcp icpId={icpId!}>
        {(icp) => {
          return (
            <div className="flex flex-col gap-6 xl:flex-row">
              <IcpBody icp={icp} className="flex-grow" />
              <IcpContext icp={icp} className="xl:self-start xl:pt-6" />
            </div>
          )
        }}
      </EnsureProcessedIcp>
    </DefaultPageLayout>
  )
}

export default IcpContextPage
