import { Button, Modal } from '@onsaui'
import { IconLinkedIn } from '@onsaui/icons'

import useUserMe from '@/modules/user/hooks/useUserMe'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const OutreachAllConfirmModal: React.FC<{
  leadsCount: number
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}> = ({ leadsCount, isOpen, onClose, onConfirm }) => {
  const userQuery = useUserMe()

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="flex max-w-md flex-col">
      <h2 className="mb-6 font-semibold">
        Are you sure you want to outreach all {leadsCount} prospects?
      </h2>

      <p className="mb-4">
        We will start outreach process and send personal connection messages to all prospects
      </p>

      <div className="">
        <p className="mb-2 text-sm text-secondary">Outreach from this LinkedIn:</p>
        <RenderQuery query={userQuery} className="!justify-start">
          {({ linkedInConnection }) => {
            if (!linkedInConnection) {
              return <p className="text-danger">LinkedIn account not found</p>
            }

            const fullName = `${linkedInConnection.firstName} ${linkedInConnection.lastName}`

            return (
              <div className="flex flex-row items-center gap-2 overflow-hidden">
                {linkedInConnection.avatarUrl ? (
                  <img
                    src={linkedInConnection.avatarUrl}
                    alt={fullName}
                    className="h-6 w-6 shrink-0 rounded-full"
                  />
                ) : (
                  <IconLinkedIn className="h-6 w-6 shrink-0" />
                )}
                <span className="truncate">{fullName}</span>
              </div>
            )
          }}
        </RenderQuery>
      </div>

      <Button className="mt-8" onClick={onConfirm}>
        Outreach All
      </Button>
      <Button className="mt-2" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </Modal>
  )
}

export default OutreachAllConfirmModal
