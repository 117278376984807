import { notify } from '@onsaui'
import { useState } from 'react'

import { outreachActions } from '@/modules/icp'
import { OutreachTemplate } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const useOutreachTemplateActions = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const createOutreachTemplate = async (
    icpId: string,
    title: string,
    message: string,
    position: number = 100,
  ) => {
    setIsCreating(true)

    try {
      const template = await outreachActions.createOutreachTemplate(icpId, title, message, position)
      return template
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsCreating(false)
    }
  }

  const updateOutreachTemplate = async (
    icpId: string,
    templateId: string,
    changes: Partial<OutreachTemplate>,
  ) => {
    setIsUpdating(true)

    try {
      const template = await outreachActions.updateOutreachTemplate(icpId, templateId, changes)
      return template
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsUpdating(false)
    }
  }

  const deleteOutreachTemplate = async (
    icpId: string,
    templateId: string,
    onDeleted?: () => void,
  ) => {
    setIsDeleting(true)

    let isDeleted = false
    try {
      await outreachActions.deleteOutreachTemplate(icpId, templateId)

      isDeleted = true
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsDeleting(false)
    }

    if (isDeleted) {
      onDeleted?.()
    }
  }

  return {
    deleteOutreachTemplate,
    isDeleting,
    createOutreachTemplate,
    isCreating,
    updateOutreachTemplate,
    isUpdating,
  }
}

export default useOutreachTemplateActions
