import { BeautyLoader, Button, Loader } from '@onsaui'
import cx from 'clsx'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import useIcpReferenceLeads from '@/modules/icp/hooks/useIcpReferenceLeads'
import { TaskStatus } from '@/modules/task/model'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import IcpLeadItem from './IcpLeadItem'
import IcpReferenceScore from './IcpReferenceScore'

const IcpReferenceLeadsWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const referenceLeadsQuery = useIcpReferenceLeads(icpId)
  const { generateIcpReferenceLeads, isGenerating } = useIcpLeadsActions()

  const hasGenerateMoreButton =
    referenceLeadsQuery.data &&
    referenceLeadsQuery.data.leads.length < 15 &&
    referenceLeadsQuery.data.status === TaskStatus.Completed

  const handleGenerateLeads = async () => {
    await generateIcpReferenceLeads(icpId)
  }

  return (
    <div className={cx('flex flex-col rounded-3xl bg-level1 p-5', className)}>
      <div className="mb-5 flex min-h-10 flex-row items-center">
        <h3 className="font-semibold">Score References</h3>

        <div className="flex-grow" />

        {hasGenerateMoreButton ? (
          <Button
            className="mr-3"
            variant="outlined"
            size="small"
            onClick={handleGenerateLeads}
            isDisabled={isGenerating}
          >
            Generate More
          </Button>
        ) : null}

        {referenceLeadsQuery.data && referenceLeadsQuery.data.status === TaskStatus.Completed ? (
          <IcpReferenceScore icpId={icpId} />
        ) : null}
      </div>

      <RenderQuery query={referenceLeadsQuery}>
        {(leadsWithStatus) => {
          if (leadsWithStatus.leads?.length) {
            return (
              <div className="flex flex-col gap-4">
                {![TaskStatus.Completed, TaskStatus.Failed].includes(leadsWithStatus.status) ? (
                  <div className="flex flex-col items-center justify-center">
                    <p className="mb-4"> Loading more leads</p>
                    <Loader />
                  </div>
                ) : null}

                {leadsWithStatus.leads.map((icpLead) => (
                  <IcpLeadItem key={icpLead.id} icpId={icpId} icpLead={icpLead} />
                ))}
              </div>
            )
          }
          if (
            [TaskStatus.Completed, TaskStatus.Failed].includes(leadsWithStatus.status) &&
            leadsWithStatus.leads?.length === 0
          ) {
            return (
              <div className="flex flex-grow flex-col items-center justify-center">
                <h3 className="mb-4">We didn&apos;t find any relevant leads 🙁</h3>
                <Button type="button" onClick={handleGenerateLeads}>
                  Generate Again
                </Button>
              </div>
            )
          }
          return (
            <div className="flex flex-grow flex-col items-center justify-center">
              <BeautyLoader />
              <p className="mt-6 text-center">
                Good things take time.
                <br />
                We are generating leads for you...
              </p>
            </div>
          )
        }}
      </RenderQuery>
    </div>
  )
}

export default IcpReferenceLeadsWidget
