import cx from 'clsx'

import { outreachConstants, OutreachStage, OutreachStageStatus } from '@/modules/icp/model'

import OutreachStatusBadge from '../OutreachStatusBadge/OutreachStatusBadge'
import EditableStageMessage from './EditableStageMessage'

const OutreachStageItem: React.FC<{
  icpId: string
  leadId: string
  stage: OutreachStage
  className?: string
}> = ({ icpId, leadId, stage, className }) => {
  const isActive = Boolean(stage.status)
  const stageName = outreachConstants.getOutreachStageName(stage.type)

  const timeLabel =
    stage.finish?.format('YYYY.MM.DD HH:mm') || stage.start?.format('YYYY.MM.DD HH:mm')

  const { message } = stage

  return (
    <div className="flex flex-row gap-3">
      <div className="flex flex-col items-center">
        <div
          className={cx('h-6 w-6 rounded-full', {
            'bg-primary': isActive,
            'bg-disabled': !isActive,
          })}
        />
        <div
          className={cx('w-[1px] flex-grow', {
            'bg-primary': isActive,
            'bg-disabled': !isActive,
          })}
        />
      </div>
      <div className={cx('min-h-8 flex-grow rounded-[10px] border border-disabled p-5', className)}>
        <div className="flex flex-row items-center">
          <p>{stageName}</p>

          {stage.status ? (
            <OutreachStatusBadge stage={stage.type} status={stage.status} className="ml-3" />
          ) : null}

          {timeLabel ? <p className="ml-auto text-sm text-secondary">{timeLabel}</p> : null}
        </div>

        {message ? (
          <EditableStageMessage
            className="mt-4"
            icpId={icpId}
            leadId={leadId}
            stage={stage}
            isEditable={!stage.status || stage.status === OutreachStageStatus.Scheduled}
          />
        ) : null}
      </div>
    </div>
  )
}

export default OutreachStageItem
