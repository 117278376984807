import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpBody from '@/modules/icp/components/IcpContextWidget/IcpBody'
import IcpStageWidget from '@/modules/icp/components/IcpStageWidget/IcpStageWidget'
import { IcpStage } from '@/modules/icp/model'

const IcpConfirmationPage: React.FC = () => {
  const { icpId } = useParams()
  return (
    <DefaultPageLayout>
      <IcpStageWidget icpId={icpId!} currentStage={IcpStage.Confirmation} className="mb-5" />

      <EnsureProcessedIcp icpId={icpId!}>
        {(icp) => {
          return (
            <>
              <IcpBody icp={icp} className="flex-grow" />
              {icp.changelog ? (
                <div className="mt-5 rounded-3xl bg-level1 p-5 pl-10">
                  <h2 className="mb-6 font-semibold">Changes made to the ICP</h2>
                  <p className="whitespace-pre-wrap">{icp.changelog}</p>
                </div>
              ) : null}
            </>
          )
        }}
      </EnsureProcessedIcp>
    </DefaultPageLayout>
  )
}

export default IcpConfirmationPage
