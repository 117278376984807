import dayjs from 'dayjs'

import { OutreachDetails, OutreachTemplate } from '../model/outreachInterfaces'
import { OutreachDetailsResponse, OutreachTemplateResponse } from './outreachApiInterfaces'

export const outreachTemplateAdapter = (response: OutreachTemplateResponse) => {
  const template: OutreachTemplate = { ...response, liked: response.liked ?? false }

  return template
}

export const outreachDetailsAdapter = (response: OutreachDetailsResponse) => {
  const details: OutreachDetails = {
    fullName: response.fullName,
    linkedInUrl: response.linkedInUrl,
    stages: response.actions.map((action) => ({
      type: action.type,
      status: action.status ?? undefined,
      start: action.start ? dayjs(action.start) : undefined,
      finish: action.finish ? dayjs(action.finish) : undefined,
      message: action.message ?? undefined,
      replyMessage: action.replyMessage ?? undefined,
      waitUntil: action.waitUntil ? dayjs(action.waitUntil) : undefined,
    })),
    isRunning: response.isRunning,
    isTerminalState: response.isTerminalState,
  }

  return details
}
