import { Button, notify, TextArea } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'

import { icpActions } from '@/modules/icp'
import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import { Icp } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

import IcpFiles from './IcpFiles'

const IcpContext: React.FC<{ icp: Icp; className?: string }> = ({ icp, className }) => {
  const [context, setContext] = useState('')
  const handleContextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContext(e.target.value)
  }

  const { isUpdating, updateIcp } = useIcpActions()
  const handleUpdateContext = async (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (context === icp.context) {
      return
    }

    const updatedIcp = await updateIcp(icp.id, { context })
    setContext(updatedIcp.context)
  }

  const handleRegenerate = async () => {
    try {
      await icpActions.regenerateIcp(icp.id)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    }
  }

  return (
    <div className={cx('flex min-w-[320px] flex-col rounded-3xl bg-level1 p-5', className)}>
      <h3 className="mb-4 font-semibold">Add more context</h3>

      <TextArea
        value={context}
        onChange={handleContextChange}
        placeholder="Any additional info to make ICP more accurate"
        onBlur={handleUpdateContext}
      />

      <IcpFiles icp={icp} className="mt-2" />

      <Button className="mt-4" variant="outlined" onClick={handleRegenerate}>
        Regenerate with context
      </Button>
    </div>
  )
}

export default IcpContext
