import cx from 'clsx'

import { outreachConstants, OutreachStageStatus, OutreachStageType } from '@/modules/icp/model'

const getStatusType = (status?: OutreachStageStatus) => {
  switch (status) {
    case OutreachStageStatus.Positive:
      return 'success'
    case OutreachStageStatus.Negative:
      return 'danger'
    case OutreachStageStatus.StoppedByUser:
      return 'danger'
    default:
      return 'default'
  }
}

const OutreachStatusBadge: React.FC<{
  stage: OutreachStageType
  status?: OutreachStageStatus
  className?: string
}> = ({ stage, status, className }) => {
  const statusLabel = status ? outreachConstants.getOutreachStatusLabel(stage, status) : undefined
  const statusType = getStatusType(status)

  return (
    <p
      className={cx(
        'rounded-[8px] px-3 py-1.5 text-center text-sm',
        {
          'bg-[#99FCBB] text-[#23A24E]': statusType === 'success',
          'bg-[#FFAEAE] text-[#FF5050]': statusType === 'danger',
          'bg-[#DCE1E9] text-[#768091]': statusType === 'default',
        },
        className,
      )}
    >
      {statusLabel}
    </p>
  )
}

export default OutreachStatusBadge
