import { IconArrowLeftSm } from '@onsaui/icons'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import OutreachDetailsWidget from '@/modules/icp/components/OutreachDetailsWidget/OutreachDetailsWidget'

const IcpOutreachDetailsPage: React.FC = () => {
  const { icpId, leadId } = useParams()
  const [searchParams] = useSearchParams()
  const isBackToCampaign = searchParams.get('backToCampaign')

  return (
    <DefaultPageLayout>
      {isBackToCampaign ? (
        <Link to="../campaign">
          <button
            type="button"
            className="mb-5 inline-flex h-10 flex-row items-center justify-center self-start transition-all hover:text-primary"
          >
            <IconArrowLeftSm className="mr-1" />
            <span>Back to Campaign</span>
          </button>
        </Link>
      ) : null}

      <OutreachDetailsWidget icpId={icpId!} leadId={leadId!} />
    </DefaultPageLayout>
  )
}

export default IcpOutreachDetailsPage
