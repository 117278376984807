import cx from 'clsx'

const StatSlot: React.FC<{
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>
  value: React.ReactNode
  description: React.ReactNode
  className?: string
}> = ({ Icon, value, description, className }) => {
  return (
    <div
      className={cx(
        'flex min-h-[84px] flex-row items-center justify-center rounded-[10px] bg-primary-surface px-4',
        className,
      )}
    >
      {Icon ? (
        <div className="mr-2 shrink-0 rounded-full bg-primary p-2 text-white">
          <Icon className="h-5 w-5" />
        </div>
      ) : null}
      <p className="mr-3 text-4xl text-primary">{value}</p>
      <p className="text-sm">{description}</p>
    </div>
  )
}

export default StatSlot
