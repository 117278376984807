import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpStageWidget from '@/modules/icp/components/IcpStageWidget/IcpStageWidget'
import OutreachTemplatesWidget from '@/modules/icp/components/OutreachTemplatesWidget/OutreachTemplatesWidget'
import { IcpStage } from '@/modules/icp/model'

const IcpTemplatesPage: React.FC = () => {
  const { icpId } = useParams()

  return (
    <DefaultPageLayout>
      <IcpStageWidget icpId={icpId!} currentStage={IcpStage.MessageTemplates} className="mb-5" />
      <EnsureProcessedIcp icpId={icpId!}>
        {(icp) => {
          return <OutreachTemplatesWidget className="flex-grow" icpId={icpId!} />
        }}
      </EnsureProcessedIcp>
    </DefaultPageLayout>
  )
}

export default IcpTemplatesPage
