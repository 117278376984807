import { Button, Modal } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'

import PriceBadge from '@/modules/billing/components/PriceBadge/PriceBadge'

const CreateTaskCard: React.FC<{
  Illustration: React.FC<React.SVGProps<SVGSVGElement>>
  title: React.ReactNode
  description: React.ReactNode
  buttonLabel: React.ReactNode
  modalContent: React.ReactNode
  className?: string
  price?: number
}> = ({ Illustration, title, description, price, buttonLabel, modalContent, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div
      className={cx(
        'relative flex max-w-xs flex-col items-center rounded-[20px] bg-level1 px-5 pb-5 pt-8',
        className,
      )}
    >
      <Modal
        className="flex max-w-md flex-col items-center"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        {modalContent}
      </Modal>

      {price && <PriceBadge price={price} className="absolute -right-3 top-2 -translate-y-1/2" />}

      <Illustration className="mb-8" />

      <h3 className="mb-2 text-center font-semibold">{title}</h3>
      <p className="mb-8 text-center">{description}</p>

      <div className="flex-grow" />

      <Button className="w-full" onClick={() => setIsModalOpen(true)}>
        {buttonLabel}
      </Button>
    </div>
  )
}

export default CreateTaskCard
