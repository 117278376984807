import { icpLeadsApi } from '@/modules/icp/api'
import { IcpLead, IcpLeadsWithStatus, LeadStatus } from '@/modules/icp/model'
import { TaskStatus } from '@/modules/task/model'
import { QueryService } from '@/services/query'

export const invalidateIcpReferenceLeads = (icpId: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['icpReferenceLeads', icpId] })
}
export const invalidateIcpCampaignLeads = (icpId: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['icpCampaignLeads', icpId] })
}

export const setIcpCampaignLeads = (
  icpId: string,
  updater: (oldData?: IcpLeadsWithStatus) => IcpLeadsWithStatus | undefined,
) => {
  QueryService.getClient().setQueriesData({ queryKey: ['icpCampaignLeads', icpId] }, updater)
}

export const setIcpReferenceLeads = (
  icpId: string,
  updater: (oldData?: IcpLeadsWithStatus) => IcpLeadsWithStatus | undefined,
) => {
  QueryService.getClient().setQueriesData({ queryKey: ['icpReferenceLeads', icpId] }, updater)
}

export const setIcpLead = (icpId: string, lead: IcpLead) => {
  const queryUpdater = (oldData?: IcpLeadsWithStatus) => {
    if (!oldData) {
      return
    }

    let { leads } = oldData
    if (lead.status === LeadStatus.Deleted) {
      leads = leads.filter((oldLead) => oldLead.id !== lead.id)
    } else {
      leads = leads.map((oldLead) => (oldLead.id === lead.id ? lead : oldLead))
    }

    return { ...oldData, leads }
  }

  setIcpReferenceLeads(icpId, queryUpdater)
  invalidateIcpReferenceLeads(icpId)

  setIcpCampaignLeads(icpId, queryUpdater)
  invalidateIcpCampaignLeads(icpId)
}

export const generateIcpReferenceLeads = async (icpId: string) => {
  await icpLeadsApi.generateIcpReferenceLeads(icpId)

  setIcpReferenceLeads(icpId, (oldData?: IcpLeadsWithStatus) => {
    if (!oldData) {
      return
    }

    return {
      ...oldData,
      status: TaskStatus.Processing,
    }
  })

  invalidateIcpReferenceLeads(icpId)
}

export const generateIcpCampaignLeads = async (icpId: string) => {
  await icpLeadsApi.generateIcpLeads(icpId)

  setIcpCampaignLeads(icpId, (oldData?: IcpLeadsWithStatus) => {
    if (!oldData) {
      return
    }

    return {
      ...oldData,
      status: TaskStatus.Processing,
    }
  })

  invalidateIcpCampaignLeads(icpId)
}

export const updateIcpLead = async (icpId: string, leadId: string, changes: Partial<IcpLead>) => {
  const lead = await icpLeadsApi.updateIcpLead(icpId, leadId, changes)

  setIcpLead(icpId, lead)

  return lead
}

export const deleteIcpLead = async (icpId: string, leadId: string, comment?: string) => {
  return updateIcpLead(icpId, leadId, { status: LeadStatus.Deleted, comment })
}

export const exportCampaignLeadsAsCsv = async (icpId: string, name: string = 'leads') => {
  const response = await icpLeadsApi.exportLeadsAsCsv(icpId)
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${name}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
