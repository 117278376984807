import { notify } from '@onsaui'
import { useState } from 'react'

import { icpLeadsActions } from '@/modules/icp'
import { icpLeadsApi } from '@/modules/icp/api'
import { IcpLead } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const useIcpLeadsActions = () => {
  const [isGenerating, setIsGenerating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const generateIcpReferenceLeads = async (icpId: string) => {
    setIsGenerating(true)

    try {
      await icpLeadsActions.generateIcpReferenceLeads(icpId)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsGenerating(false)
    }
  }

  const generateIcpCampaignLeads = async (icpId: string) => {
    setIsGenerating(true)

    try {
      await icpLeadsActions.generateIcpCampaignLeads(icpId)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsGenerating(false)
    }
  }

  const updateIcpLead = async (icpId: string, leadId: string, changes: Partial<IcpLead>) => {
    setIsUpdating(true)

    try {
      return await icpLeadsActions.updateIcpLead(icpId, leadId, changes)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsUpdating(false)
    }
  }

  const deleteIcpLead = async (icpId: string, leadId: string, comment?: string) => {
    setIsDeleting(true)

    try {
      await icpLeadsActions.deleteIcpLead(icpId, leadId, comment)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsDeleting(false)
    }
  }

  const createIcpLeadMemo = async (icpId: string, leadId: string) => {
    try {
      return await icpLeadsApi.createIcpLeadMemo(icpId, leadId)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    }
  }

  const exportCampaignLeadsAsCsv = async (icpId: string, name?: string) => {
    setIsExporting(true)
    try {
      return await icpLeadsActions.exportCampaignLeadsAsCsv(icpId, name)
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsExporting(false)
    }
  }

  return {
    generateIcpReferenceLeads,
    generateIcpCampaignLeads,
    isGenerating,
    deleteIcpLead,
    isDeleting,
    updateIcpLead,
    isUpdating,
    createIcpLeadMemo,
    exportCampaignLeadsAsCsv,
    isExporting,
  }
}

export default useIcpLeadsActions
