import { Button } from '@onsaui'
import { Link, useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpCampaignLeadsWidget from '@/modules/icp/components/IcpCampaignLeadsWidget/IcpCampaignLeadsWidget'
import IcpStatisticsWidget from '@/modules/icp/components/IcpStatisticsWidget/IcpStatisticsWidget'
import { IcpStage } from '@/modules/icp/model'

const IcpCampaignPage: React.FC = () => {
  const { icpId } = useParams()

  return (
    <EnsureProcessedIcp icpId={icpId!}>
      {(icp) => {
        if (icp.stage !== IcpStage.Finalized) {
          return <p>Campaign is not ready</p>
        }

        return (
          <DefaultPageLayout>
            <div className="mb-5 flex flex-row items-center">
              <h2 className="font-semibold">{icp.title}</h2>

              <Button className="ml-2" variant="outlined" size="medium" asChild>
                <Link to="../view?backToCampaign=true">View ICP</Link>
              </Button>
            </div>

            <IcpStatisticsWidget icpId={icpId!} className="mb-6" />
            <IcpCampaignLeadsWidget icpId={icpId!} icpTitle={icp.title} className="flex-grow" />
          </DefaultPageLayout>
        )
      }}
    </EnsureProcessedIcp>
  )
}

export default IcpCampaignPage
