import { Button, notify } from '@onsaui'
import { IconClose, IconMenuDots, IconPersonAvatar, IconTrash } from '@onsaui/icons'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import cx from 'clsx'
import { Link, useNavigate, useParams } from 'react-router-dom'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import useOutreachActions from '@/modules/icp/hooks/useOutreachActions'
import { IcpLead, OutreachStageStatus } from '@/modules/icp/model'
import { openConfirmModal } from '@/modules/shared'

import OutreachStatusBadge from '../OutreachStatusBadge/OutreachStatusBadge'

const CampaignLeadItem: React.FC<{
  icpId: string
  lead: IcpLead
  className?: string
}> = ({ icpId, lead, className }) => {
  const { workspaceId } = useParams()
  const navigate = useNavigate()

  const { createIcpLeadMemo, deleteIcpLead, updateIcpLead, isUpdating } = useIcpLeadsActions()
  const { stopOutreachLead, isProcessing } = useOutreachActions()

  // const [message, setMessage] = useState(lead.message ?? '')
  // const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setMessage(e.target.value)
  // }
  // useEffect(() => {
  //   setMessage(lead.message ?? '')
  // }, [lead.message])

  // const handleSaveMessage = async () => {
  //   if (message === (lead.message ?? '')) {
  //     return
  //   }

  //   const updatedLead = await updateIcpLead(icpId, lead.id, { message })
  //   setMessage(updatedLead.message ?? '')

  //   notify({ message: 'Updated', variant: 'success' })
  // }

  // const handleCopyMessage = () => {
  //   copyToClipboardAndNotify(message)
  // }

  const handleStopOutreach = () => {
    stopOutreachLead(icpId, lead.id)
  }

  const handleCreateMemo = async () => {
    const taskId = await createIcpLeadMemo(icpId, lead.id)
    navigate(`/${workspaceId}/t/${taskId}`)
  }
  const handleOpenMemo = () => {
    navigate(`/${workspaceId}/t/${lead.memoId}`)
  }

  const handleDelete = async () => {
    const isConfirmed = await openConfirmModal({
      body: (
        <div>
          <h2 className="mb-1 font-semibold">Delete lead</h2>
          <p>Are you sure you want to delete this lead?</p>
        </div>
      ),
      confirmLabel: 'Delete',

      action: () => deleteIcpLead(icpId, lead.id),
    })
    if (isConfirmed) {
      notify({ message: 'Deleted', variant: 'success' })
    }
  }

  const isOutreachRunning = Boolean(
    lead.outreachStage &&
      (lead.outreachStage.status === OutreachStageStatus.Pending ||
        lead.outreachStage.status === OutreachStageStatus.Scheduled),
  )

  return (
    <div
      key={lead.id}
      className={cx(
        'flex flex-row items-center rounded-[20px] border border-disabled p-4',
        className,
      )}
    >
      <div className="flex min-w-[250px] flex-row items-center gap-2">
        <a className="text-primary" href={lead.linkedInUrl} rel="noreferrer" target="_blank">
          {lead.fullName}
        </a>
        <p className="rounded-md bg-disabled-surface px-2 py-1">
          <span className="font-bold">{lead.score}</span>/5
        </p>
      </div>

      <div className="flex-grow text-sm">
        <div className="max-w-[270px]">
          <p>
            <span>Current Role: </span>
            {lead.position}
          </p>

          <p>
            <span>Company: </span>
            <a
              className="text-primary"
              href={lead.companyLinkedInUrl}
              rel="noreferrer"
              target="_blank"
            >
              {lead.companyName}
            </a>
          </p>
        </div>
      </div>

      {lead.outreachStage?.status ? (
        <OutreachStatusBadge
          className="ml-3"
          stage={lead.outreachStage.type}
          status={lead.outreachStage.status}
        />
      ) : null}

      <Button asChild variant="outlined" size="small" className="ml-3">
        <Link to={`../outreach/${lead.id}?backToCampaign=true`}>Details</Link>
      </Button>

      <DropdownMenuPrimitive.Root modal={false}>
        <DropdownMenuPrimitive.Trigger asChild>
          <button
            type="button"
            className="ml-6 flex h-10 w-10 shrink-0 flex-row items-center justify-center bg-transparent hover:text-primary"
            aria-label="Open lead context menu"
          >
            <IconMenuDots />
          </button>
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Content
          className="min-w-[280px] rounded-3xl bg-level1 px-0 py-3 shadow-lg"
          side="bottom"
          align="end"
          sideOffset={8}
        >
          {isOutreachRunning ? (
            <>
              <DropdownMenuPrimitive.Item
                onSelect={handleStopOutreach}
                className="flex w-full cursor-pointer items-center justify-start px-6 py-6 text-left hover:text-danger"
                disabled={isProcessing}
              >
                <IconClose className="mr-4" />
                Stop Outreach
              </DropdownMenuPrimitive.Item>

              <hr className="border-disabled" />
            </>
          ) : null}

          <DropdownMenuPrimitive.Item
            onSelect={lead.memoId ? handleOpenMemo : handleCreateMemo}
            className="flex w-full cursor-pointer items-center justify-start px-6 py-6 text-left hover:text-primary"
          >
            <IconPersonAvatar className="mr-4" />

            {lead.memoId ? <span>Open Memo</span> : <span>Create Memo</span>}
          </DropdownMenuPrimitive.Item>

          <hr className="border-disabled" />

          <DropdownMenuPrimitive.Item
            onSelect={handleDelete}
            className="flex w-full cursor-pointer items-center justify-start px-6 py-6 text-left hover:text-danger"
          >
            <IconTrash className="mr-4" />

            <span>Delete from the list</span>
          </DropdownMenuPrimitive.Item>
        </DropdownMenuPrimitive.Content>
      </DropdownMenuPrimitive.Root>
    </div>
  )
}

export default CampaignLeadItem
